// React Basic and Bootstrap
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const Pricing = (props) => {
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_HOST}/subscriptions/prices`).then(
      async (response) => {
        const data = await response.json();
        console.log(data.data.data);
        setPrices(data.data.data.sort((a, b) => a.unit_amount - b.unit_amount));
      },
    );
  }, []);
  return (
    <React.Fragment>
      <section id="sectionPricing" className="section">
        <Container>
          <Row className="mt-lg-4 align-items-center">
            <Col lg={5} md={12} className="text-center text-lg-start">
              <div className="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
                <h4 className="title mb-4">
                  Prendre mon abonnement ResellCorner
                </h4>
                {/* <p className="text-muted para-desc mx-auto mb-0">
                  Start working with{' '}
                  <span className="text-primary fw-bold">Landrick</span> that
                  can provide everything you need to generate awareness, drive
                  traffic, connect.
                </p>
                <Link
                  to="//1.envato.market/landrickreactjs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mt-4"
                >
                  Buy Now{' '}
                  <span className="badge rounded-pill bg-danger ms-2">
                    v4.2
                  </span>
                </Link> */}
              </div>
            </Col>

            <Col lg={7} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div className="ms-lg-5">
                <Row className="align-items-center">
                  {[...prices].map((price, key) => (
                    <Col
                      key={price.id}
                      md={6}
                      className={
                        key === 0
                          ? 'col-12 px-md-0'
                          : 'mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0'
                      }
                    >
                      <Card
                        className={`pricing pricing-primary ${
                          key === 0 ? 'starter-plan' : 'bg-light'
                        } shadow rounded border-0`}
                      >
                        <CardBody className="py-5">
                          <h6 className="title name fw-bold text-uppercase mb-4">
                            {price.product.name}
                          </h6>
                          <div className="d-flex mb-4">
                            <span className="price h1 mb-0">
                              {price.unit_amount / 100}
                            </span>
                            <span className="h4 mb-0 mt-2">€</span>
                            <span className="h4 align-self-end mb-1">
                              /mois
                            </span>
                          </div>

                          <ul className="list-unstyled mb-0 ps-0">
                            {price.product.features.map(({ name }) => (
                              <li key={name} className="h6 text-muted mb-0">
                                <span className="icon h5 me-2">
                                  <i className="uil uil-check-circle align-middle"></i>
                                </span>
                                {name}
                              </li>
                            ))}
                          </ul>
                          <a
                            href={`${process.env.REACT_APP_SECURE_HOST}/register`}
                            className="btn btn-primary mt-4"
                          >
                            Choisir
                          </a>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}

                  {/* <Col
                    md={6}
                    xs={12}
                    className="mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0"
                  >
                    <Card className="pricing pricing-primary bg-light shadow rounded border-0">
                      <CardBody className="py-5">
                        <h6 className="title name fw-bold text-uppercase mb-4">
                          Professional
                        </h6>
                        <div className="d-flex mb-4">
                          <span className="h4 mb-0 mt-2">$</span>
                          <span className="price h1 mb-0">59</span>
                          <span className="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul className="list-unstyled mb-0 ps-0">
                          <li className="h6 text-muted mb-0">
                            <span className="icon h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Full Access
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="icon h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Source Files
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="icon h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Free Appointments
                          </li>
                          <li className="h6 text-muted mb-0">
                            <span className="icon h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            Enhanced Security
                          </li>
                        </ul>
                        <Link to="#" className="btn btn-primary mt-4">
                          Try it now
                        </Link>
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Pricing;
