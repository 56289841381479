// React Basic and Bootstrap
import React, { Component } from 'react';

// Import Generic Components
import Section from './section';
import Partners from './Partners';
import HowItWorks from './HowItWorks';
import Testi from './Testi';
import Pricing from './Pricing';
import Faq from './Faq';
import Popup from '../../components/Layout/popup';
import { Container, Row } from 'reactstrap';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn').classList.add('btn-primary');
        document.querySelector('.shoppingbtn').classList.remove('btn-light');
        document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <ol>
                <li>
                  <p>
                    <strong>
                      Q : Quel type de service propose Resell Corner et comment
                      s&apos;int&egrave;gre-t-il au Retail Arbitrage sur Amazon
                      ?
                    </strong>{' '}
                    A : Resell Corner offre un service par abonnement
                    sp&eacute;cifiquement con&ccedil;u pour les revendeurs
                    pratiquant le Retail Arbitrage sur des plateformes telles
                    qu&apos;Amazon. Il propose des outils d&apos;analyse et de
                    gestion permettant d&apos;optimiser les performances, de
                    surveiller les stocks et d&apos;analyser les donn&eacute;es
                    de revente en ligne.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Q : Comment puis-je m&apos;abonner &agrave; Resell Corner
                      et choisir un forfait adapt&eacute; &agrave; mon
                      activit&eacute; de Retail Arbitrage sur Amazon ?
                    </strong>{' '}
                    A : Pour vous abonner, rendez-vous sur notre site web et
                    s&eacute;lectionnez le forfait correspondant le mieux
                    &agrave; votre strat&eacute;gie de Retail Arbitrage sur
                    Amazon. Une fois votre choix effectu&eacute;, vous pourrez
                    proc&eacute;der au paiement en ligne.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Q : Comment puis-je mettre fin &agrave; mon abonnement
                      &agrave; Resell Corner pour mon activit&eacute; de Retail
                      Arbitrage sur Amazon ?
                    </strong>{' '}
                    A : Vous pouvez annuler votre abonnement à tout moment via votre dashboard Resell Corner.
Dans le cas d'une suppression de votre compte, vous pouvez envoyez un mail à 
                    <a href="mailto:contact@resellcorner.io">
                      contact@resellcorner.io
                    </a>{' '}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Q : Est-ce que Resell Corner est accessible pour pratiquer
                      le Retail Arbitrage sur Amazon partout dans le monde ?
                    </strong>{' '}
                    A : Oui, Resell Corner est utilisable &agrave;
                    l&apos;&eacute;chelle mondiale, du moment que vous disposez
                    d&apos;un ordinateur connect&eacute; &agrave; Internet.
                    Notre plateforme est accessible via n&apos;importe quel
                    navigateur web, ce qui en fait un outil pratique pour les
                    revendeurs pratiquant le Retail Arbitrage sur Amazon dans le
                    monde entier.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Q : Offrez-vous un support client pour les utilisateurs de
                      Resell Corner actifs dans le Retail Arbitrage sur Amazon ?
                    </strong>{' '}
                    A : Absolument, nous proposons un support client
                    d&eacute;di&eacute; pour r&eacute;pondre &agrave; toutes vos
                    questions sp&eacute;cifiques au Retail Arbitrage sur Amazon.
                    N&apos;h&eacute;sitez pas &agrave; nous contacter via notre
                    adresse e-mail{' '}
                    <a href="mailto:contact@resellcorner.io">
                      contact@resellcorner.io
                    </a>{' '}
                    pour toute assistance requise.
                  </p>
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default IndexMain;
