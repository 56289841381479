import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// import images
import startupSVG from '../../assets/images/illustrator/Startup_SVG.svg';

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={7} md={7}>
                <div className="title-heading me-lg-4">
                  <h1 className="heading mb-3">
                    Accélérez votre recherche et décuplez votre efficacité avec{' '}
                    <span className="text-primary">ResellCorner</span>{' '}
                  </h1>
                  <p className="para-desc text-muted">
                    Optimisez votre productivité grâce à un espace de travail
                    centralisé qui relie une base de données exhaustive de plus
                    de 500 sites de retail et wholesale, vous permettant de
                    trouver rapidement les produits les plus rentables pour
                    arbitrage sur Amazon{' '}
                  </p>
                  <div className="mt-4">
                    <a href={`${process.env.REACT_APP_SECURE_HOST}/register`}>
                      <button className="btn btn-primary mt-2 me-2">
                        <i className="uil uil-envelope"></i> S'inscrire
                      </button>{' '}
                    </a>
                    <a
                      href="/tutoriel"
                      className="btn btn-outline-primary mt-2"
                    >
                      <i className="uil uil-book-alt"></i> Video de présentation
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src={startupSVG} alt="" />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
