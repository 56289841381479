// React Basic and Bootstrap
import React, { Component } from 'react';

// Import Generic Components
import Section from './section';
import Partners from './Partners';
import HowItWorks from './HowItWorks';
import Testi from './Testi';
import Pricing from './Pricing';
import Faq from './Faq';
import Popup from '../../components/Layout/popup';
import { Container, Row } from 'reactstrap';

class Confidentials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn').classList.add('btn-primary');
        document.querySelector('.shoppingbtn').classList.remove('btn-light');
        document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <h1>Politique de confidentialit&eacute; de ResellCorner&nbsp;</h1>
              <p>
                <br />
              </p>
              <p>
                La pr&eacute;sente Politique de confidentialit&eacute;
                d&eacute;crit la fa&ccedil;on dont vos informations personnelles
                sont recueillies, utilis&eacute;es et partag&eacute;es lorsque
                vous vous rendez sur www.resellcorner.io (le &laquo; Site
                &raquo;) ou que vous y effectuez un achat.
              </p>
              <p>
                <br />
              </p>
              <p>INFORMATIONS PERSONNELLES RECUEILLIES</p>
              <p>
                <br />
              </p>
              <p>
                Lorsque vous vous rendez sur le Site, nous recueillons
                automatiquement certaines informations concernant votre
                appareil, notamment des informations sur votre navigateur web,
                votre adresse IP, votre fuseau horaire et certains des cookies
                qui sont install&eacute;s sur votre appareil. En outre, lorsque
                vous parcourez le Site, nous recueillons des informations sur
                les pages web ou produits individuels que vous consultez, les
                sites web ou les termes de recherche qui vous ont permis
                d&apos;arriver sur le Site, ainsi que des informations sur la
                mani&egrave;re dont vous interagissez avec le Site. Nous
                d&eacute;signons ces informations collect&eacute;es
                automatiquement sous l&apos;appellation
                &laquo;&nbsp;Informations sur l&apos;appareil&nbsp;&raquo;.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous recueillons les Informations sur l&apos;appareil &agrave;
                l&apos;aide des technologies suivantes&nbsp;:
              </p>
              <p>
                <br />
              </p>
              <p>&nbsp; FICHIERS T&Eacute;MOINS (COOKIES)</p>
              <p>
                <br />
              </p>
              <p>
                Voici une liste de fichiers t&eacute;moins que nous utilisons.
                Nous les avons &eacute;num&eacute;r&eacute;s ici pour que vous
                ayez la possibilit&eacute; de choisir si vous souhaitez les
                autoriser ou non.
              </p>
              <p>
                <br />
              </p>
              <p>
                &nbsp; - Les &laquo; fichiers journaux &raquo; suivent
                l&apos;activit&eacute; du Site et recueillent des donn&eacute;es
                telles que votre adresse IP, le type de navigateur que vous
                utilisez, votre fournisseur d&apos;acc&egrave;s Internet, vos
                pages r&eacute;f&eacute;rentes et de sortie, et vos
                donn&eacute;es d&apos;horodatage (date et heure).
              </p>
              <p>
                <br />
              </p>
              <p>
                &nbsp; &nbsp; - Les &laquo; pixels invisibles &raquo;, les
                &laquo; balises &raquo; et les &laquo; pixels &raquo; sont des
                fichiers &eacute;lectroniques qui enregistrent des informations
                sur la fa&ccedil;on dont vous parcourez le Site.
              </p>
              <p>
                <br />
              </p>
              <p>
                Par ailleurs, lorsque vous effectuez ou tentez d&apos;effectuer
                un achat par le biais du Site, nous recueillons certaines
                informations vous concernant, notamment votre nom, votre adresse
                de facturation, votre adresse d&apos;exp&eacute;dition, vos
                informations de paiement (y compris vos num&eacute;ros de cartes
                de cr&eacute;dit, votre adresse e-mail et votre num&eacute;ro de
                t&eacute;l&eacute;phone. &nbsp;
              </p>
              <p>
                <br />
              </p>
              <p>
                Ces informations collect&eacute;es automatiquement sont
                d&eacute;sign&eacute;es par l&rsquo;appellation
                &laquo;&nbsp;Informations sur la commande&nbsp;&raquo;.
              </p>
              <p>
                <br />
              </p>
              <p>
                Lorsque nous utilisons l&apos;expression
                &laquo;&nbsp;Informations personnelles&nbsp;&raquo; dans la
                pr&eacute;sente Politique de confidentialit&eacute;, nous
                faisons allusion &agrave; la fois aux Informations sur
                l&apos;appareil et aux Informations sur la commande.
              </p>
              <p>
                <br />
              </p>
              <p>COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES&nbsp;?</p>
              <p>
                <br />
              </p>
              <p>
                En r&egrave;gle g&eacute;n&eacute;rale, nous utilisons les
                Informations sur la commande que nous recueillons pour traiter
                toute commande pass&eacute;e par le biais du Site (y compris
                pour traiter vos informations de paiement, organiser
                l&apos;exp&eacute;dition de votre commande et vous fournir des
                factures et/ou des confirmations de commande). &nbsp;
              </p>
              <p>
                En outre, nous utilisons ces Informations sur la commande
                pour&nbsp;:
              </p>
              <p>communiquer avec vous&nbsp;;</p>
              <p>&eacute;valuer les fraudes ou risques potentiels&nbsp;; et</p>
              <p>
                lorsque cela correspond aux pr&eacute;f&eacute;rences que vous
                nous avez communiqu&eacute;es, vous fournir des informations ou
                des publicit&eacute;s concernant nos produits ou services.
              </p>
              <p>
                <br />
              </p>
              <p>
                Nous utilisons les Informations sur l&apos;appareil (en
                particulier votre adresse IP) que nous recueillons pour
                &eacute;valuer les fraudes ou risques potentiels et, de
                mani&egrave;re plus g&eacute;n&eacute;rale, pour
                am&eacute;liorer et optimiser notre Site (par exemple, en
                g&eacute;n&eacute;rant des analyses sur la fa&ccedil;on dont nos
                clients parcourent et interagissent avec le Site, et pour
                &eacute;valuer la r&eacute;ussite de nos campagnes de
                publicit&eacute; et de marketing).
              </p>
              <p>
                <br />
              </p>
              <p>PARTAGE DE VOS INFORMATIONS PERSONNELLES</p>
              <p>
                <br />
              </p>
              <p>
                Nous partageons vos Informations personnelles avec des tiers qui
                nous aident &agrave; les utiliser aux fins d&eacute;crites
                pr&eacute;c&eacute;demment. &nbsp;Par exemple, nous utilisons
                Godaddy pour h&eacute;berger notre site en ligne &ndash; pour en
                savoir plus sur l&apos;utilisation de vos Informations
                personnelles parGodaddy, veuillez consulter la page suivante :
                https://www.godaddy.com/fr-fr/legal/agreements . Nous utilisons
                &eacute;galement Google Analytics pour mieux comprendre comment
                nos clients utilisent le Site &ndash; pour en savoir plus sur
                l&apos;utilisation de vos Informations personnelles par Google,
                veuillez consulter la page suivante :
                https://www.google.com/intl/fr/policies/privacy/. &nbsp;Vous
                pouvez aussi d&eacute;sactiver Google Analytics ici :
                https://tools.google.com/dlpage/gaoptout.
              </p>
              <p>
                <br />
              </p>
              <p>
                Enfin, il se peut que nous partagions aussi vos Informations
                personnelles pour respecter les lois et r&egrave;glementations
                applicables, r&eacute;pondre &agrave; une assignation, &agrave;
                un mandat de perquisition ou &agrave; toute autre demande
                l&eacute;gale de renseignements que nous recevons, ou pour
                prot&eacute;ger nos droits.
              </p>
              <p>
                <br />
              </p>
              <p>PUBLICIT&Eacute; COMPORTEMENTALE</p>
              <p>
                Comme indiqu&eacute; ci-dessus, nous utilisons vos Informations
                personnelles pour vous proposer des publicit&eacute;s
                cibl&eacute;es ou des messages de marketing qui, selon nous,
                pourraient vous int&eacute;resser. &nbsp;Pour en savoir plus sur
                le fonctionnement de la publicit&eacute; cibl&eacute;e, vous
                pouvez consulter la page d&apos;information de la Network
                Advertising Initiative (NAI) &agrave; l&apos;adresse suivante :
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous pouvez refuser la publicit&eacute; cibl&eacute;e ici&nbsp;:
              </p>
              <p>
                <br />
              </p>
              <p>&nbsp; QUELQUES LIENS COURANTS :</p>
              <p>
                &nbsp; &nbsp; FACEBOOK
                &ndash;&nbsp;https://www.facebook.com/settings/?tab=ads
              </p>
              <p>
                &nbsp; &nbsp; GOOGLE
                &ndash;&nbsp;https://www.google.com/settings/ads/anonymous
              </p>
              <p>
                <br />
              </p>
              <p>
                En outre, vous pouvez refuser certains de ces services en vous
                rendant sur le portail de d&eacute;sactivation de Digital
                Advertising Alliance &agrave; l&apos;adresse suivante :
                https://optout.aboutads.info/?c=3&amp;lang=fr.
              </p>
              <p>
                <br />
              </p>
              <p>NE PAS SUIVRE</p>
              <p>
                Veuillez noter que nous ne modifions pas la collecte de
                donn&eacute;es de notre Site et nos pratiques d&apos;utilisation
                lorsque nous d&eacute;tectons un signal &laquo;&nbsp;Ne pas
                suivre&nbsp;&raquo; sur votre navigateur.
              </p>
              <p>
                <br />
              </p>
              <p>VOS DROITS</p>
              <p>
                Si vous &ecirc;tes r&eacute;sident(e) europ&eacute;en(ne), vous
                disposez d&apos;un droit d&apos;acc&egrave;s aux informations
                personnelles que nous d&eacute;tenons &agrave; votre sujet et
                vous pouvez demander &agrave; ce qu&apos;elles soient
                corrig&eacute;es, mises &agrave; jour ou supprim&eacute;es. Si
                vous souhaitez exercer ce droit, veuillez nous contacter au
                moyen des coordonn&eacute;es pr&eacute;cis&eacute;es ci-dessous.
              </p>
              <p>
                Par ailleurs, si vous &ecirc;tes r&eacute;sident(e)
                europ&eacute;en(ne), notez que nous traitons vos informations
                dans le but de remplir nos obligations contractuelles &agrave;
                votre &eacute;gard (par exemple si vous passez une commande sur
                le Site) ou de poursuivre nos int&eacute;r&ecirc;ts commerciaux
                l&eacute;gitimes, &eacute;num&eacute;r&eacute;s ci-dessus.
                &nbsp;Veuillez &eacute;galement noter que vos informations
                seront transf&eacute;r&eacute;es hors de l&apos;Europe, y
                compris au Canada et aux &Eacute;tats-Unis.
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>R&Eacute;TENTION DES DONN&Eacute;ES</p>
              <p>
                Lorsque vous passez une commande par l&apos;interm&eacute;diaire
                du Site, nous conservons les Informations sur votre commande
                dans nos dossiers, sauf si et jusqu&apos;&agrave; ce que vous
                nous demandiez de les supprimer.
              </p>
              <p>
                <br />
              </p>
              <p>CHANGEMENTS</p>
              <p>
                Nous pouvons &ecirc;tre amen&eacute;s &agrave; modifier la
                pr&eacute;sente politique de confidentialit&eacute; de temps
                &agrave; autre afin d&apos;y refl&eacute;ter, par exemple, les
                changements apport&eacute;s &agrave; nos pratiques ou pour
                d&apos;autres motifs op&eacute;rationnels, juridiques ou
                r&eacute;glementaires.
              </p>
              <p>NOUS CONTACTER</p>
              <p>
                Pour en savoir plus sur nos pratiques de confidentialit&eacute;,
                si vous avez des questions ou si vous souhaitez d&eacute;poser
                une r&eacute;clamation, veuillez nous contacter par e-mail
                &agrave; contact@resellcorner.io, ou par courrier &agrave;
                l&apos;adresse suivante&nbsp;:
              </p>
              <p>
                <br />
              </p>
              <p>&nbsp;Z.A. DU BISCONTE, PLOUHINEC, 56680, France</p>
              <p>
                <br />
              </p>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Confidentials;
