// React Basic and Bootstrap
import React, { Component } from 'react';

// Import Generic Components
import Section from './section';
import Partners from './Partners';
import HowItWorks from './HowItWorks';
import Testi from './Testi';
import Pricing from './Pricing';
import Faq from './Faq';
import Popup from '../../components/Layout/popup';
import { Container, Row } from 'reactstrap';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollNavigation = this.scrollNavigation.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn').classList.add('btn-primary');
        document.querySelector('.shoppingbtn').classList.remove('btn-light');
        document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <h1>Mentions légales</h1>
              <p>
                ResellCorner est le nom commercial de FUTUR X MEDIA SAS,
                soci&eacute;t&eacute; par actions simplifi&eacute;e
                immatricul&eacute;e au RCS de Lorient sous le num&eacute;ro 981
                609 167 et ayant son si&egrave;ge social &agrave; Z.A. DU
                BISCONTE, 56680 PLOUHINEC.
              </p>
              <p>
                Veuillez noter que l&apos;utilisation des cookies et la gestion
                des donn&eacute;es personnelles sont r&eacute;gies par nos
                Conditions G&eacute;n&eacute;rales de Vente (CGV), nos
                Conditions G&eacute;n&eacute;rales d&apos;Utilisation (CGU) et
                Politique de confidentialit&eacute;, disponibles sur le site.
              </p>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default IndexMain;
